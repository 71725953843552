import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ToastService from "../../../components/ToastContainer/ToastService";
import { getUserInfo } from "../../../utils/helper";
import { trackProductView } from "../../../utils/tracking/rpiTracking";
import { getProductDetailsApiCall, getProductDetailsRewardpoints } from "../helperEcommerce";
import "./ProductDetail.css";
import { useCustomStyle } from "./ProductDetail.style";
import ProductListSlider from "./ProductListSlider";
import ProductDisplay from "./SharedComponents/ProductDisplay";
import TabDocument from "./SharedComponents/TabDocument";

const ProductDetail = ({
  productId = "",
  secondaryArgs = {},
  cartCountUpdate = () => {},
  showAdditionalDetails = true,
}: any) => {
  const userEmail = getUserInfo("email_id");
  const { t, i18n } = useTranslation();
  const classes = useCustomStyle();

  const [loading, setLoading] = useState(false);
  const [rewardPoints, set_rewardPoints] = useState("");
  const [variantsDetails, set_VariantsDetails] = useState<any>([]);
  const [productFullDetails, setProductFullDetails] = useState<any>({});

  const getPointDetails = async (fetchEcomProductDetails: any) => {
    const { ecomx_sale_price = "" } = fetchEcomProductDetails;
    const amt = ecomx_sale_price ? "" + Math.round(ecomx_sale_price) : "";
    const response = await getProductDetailsRewardpoints(amt, secondaryArgs);
    const { data = {} } = response;
    const { result = "" } = data;
    if (result === 0) {
      set_rewardPoints("");
    } else {
      set_rewardPoints(result);
    }
  };

  /**
   * id based get product details get
   */
  const getProductDetails = async () => {
    setLoading(true);
    const res = await getProductDetailsApiCall(productId, secondaryArgs, variantsDetails);
    setLoading(false);
    const { data: { data: { publish_fetchEcomProductDetails = {} } = {} } = {}, status = 0 } = res;
    if (status === 200) {
      getPointDetails(publish_fetchEcomProductDetails); //get reward point api
      setProductFullDetails(publish_fetchEcomProductDetails);
      trackProductView(publish_fetchEcomProductDetails);
    } else {
      ToastService.failToast(t("errorRequest"));
      setProductFullDetails({});
    }
  };

  /**
   * size variant handle
   * @param item
   */
  const sizeVariantsHandle = (item: any) => {
    const newArray = [...variantsDetails, item];
    const ids = newArray.map(({ key }) => key);
    const filtered = newArray.filter(({ key }: any, index) => !ids.includes(key, index + 1));
    set_VariantsDetails(filtered);
  };

  useEffect(() => {
    getProductDetails();
  }, [productId, variantsDetails]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);

  return (
    <Box className={`${classes.productDetailWrapper} productDetailpage`}>
      <ProductDisplay
        rewardPoints={rewardPoints}
        secondaryArgs={secondaryArgs}
        loading={loading}
        productFullDetails={productFullDetails}
        productId={productId}
        sizeVariantsHandle={sizeVariantsHandle}
        t={t}
        cartCountUpdate={cartCountUpdate}
      />

      <Container className='grid_container prelem-py'>
        <ProductListSlider
          title='Often Purchased Together'
          productId={productId}
          secondaryArgs={secondaryArgs}
          selectedPersonalisation={{ "personalisation-value": "upsellproducts" }}
        />
        <ProductListSlider
          title='Similar Products'
          productId={productId}
          secondaryArgs={secondaryArgs}
          selectedPersonalisation={{ "personalisation-value": "crosssellproducts" }}
        />
        {userEmail ? (
          <ProductListSlider
            title='Related Products'
            productId={productId}
            secondaryArgs={secondaryArgs}
            selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
          />
        ) : null}
      </Container>
      {showAdditionalDetails && (
        <Grid xs={12} className='prelem-py'>
          <TabDocument productFullDetails={productFullDetails} />
          <Container className='grid_container prelem-py'>
            {userEmail ? (
              <ProductListSlider
                title='Repeated Products'
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "repeatedproducts" }}
              />
            ) : (
              <ProductListSlider
                title='Related Products'
                productId={productId}
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
              />
            )}
          </Container>
        </Grid>
      )}
    </Box>
  );
};

export default ProductDetail;
