import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { RootState } from "@platformx/authoring-state";
import { AllIcon } from "@platformx/shared/static-assets";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(() => ({
  "& .Platform-x-Paper-root": {
    borderRadius: 8,
    minWidth: 285,
    padding: 5,
    boxShadow: "none",
    marginTop: "17px",
    marginLeft: "-12px",
    "& .Platform-x-MenuItem-root": {
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#F7F7FC",
      },
    },
  },
}));

export default function AllCatCta({ setCategory }) {
  const { routes = [] } = useSelector((state: RootState) => state?.routes);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedCategory, setSelectedCategory] = React.useState({
    title: "All",
    icon: AllIcon,
    category: "ALL",
    id: "all",
    menuIcon: "",
    fallbackIcon: "",
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectedCategory = (category) => {
    setSelectedCategory(category);
    setCategory(category);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImageError = (e, fallback) => {
    if (fallback) e.target.src = fallback;
  };

  return (
    <div>
      <Button
        className='allCatBtn'
        disableElevation
        onClick={handleClick}
        data-testid='allCatCta'
        endIcon={<KeyboardArrowDownIcon />}>
        <Box className='allcatctabox'>
          <Box className='icon'>
            {" "}
            {(selectedCategory?.menuIcon || selectedCategory?.icon) && (
              <img
                src={String(selectedCategory?.menuIcon || selectedCategory?.icon)}
                onError={(e) => handleImageError(e, selectedCategory?.fallbackIcon)}
                alt='icon'
              />
            )}
          </Box>
          <Typography variant='h6regular'>{t(selectedCategory?.id)}</Typography>
        </Box>
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleSelectedCategory({
              title: "All",
              icon: AllIcon,
              category: "ALL",
              id: "all",
            });
            handleClose();
          }}
          data-testid='allCatCta-list'
          key='all'>
          <Box className='allcatctabox'>
            <Box className='icon'>
              <img alt='all' src={AllIcon} />
            </Box>
            <Typography variant='h6regular'>ALL</Typography>
          </Box>
        </MenuItem>
        {routes
          ?.filter((x) => x.hasSearch)
          .map((val, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleSelectedCategory(val);
                  handleClose();
                }}
                data-testid='allCatCta-list'
                key={index}>
                <Box className='allcatctabox'>
                  <Box className='icon'>
                    {(val?.icon || val?.menuIcon) && (
                      <img
                        alt='icon'
                        src={val?.menuIcon || val?.icon}
                        onError={(e) => handleImageError(e, val?.fallbackIcon)}
                      />
                    )}
                  </Box>
                  <Typography variant='h6regular'>{t(val?.title)}</Typography>
                </Box>
              </MenuItem>
            );
          })}
      </StyledMenu>
    </div>
  );
}
