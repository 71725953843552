import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Checkbox,
  FormControlLabel,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import useGalleryStyle from "./ContentGallery.styles";

import { Tag_Icon } from "@platformx/shared/static-assets";
import { useState } from "react";

const GalleryLeftSideBar = ({
  categoriesdata,
  updateTagField,
  selectedTags,
  multi_select,
  single_select,
  categoryContent,
  categoryCollection,
}) => {
  const [count, setCount] = useState(10);
  const [isViewLess, setIsViewLess] = useState(false);
  const classes = useGalleryStyle();

  const setViewMoreTagCount = (tagLength) => {
    setCount(tagLength);
    setIsViewLess(true);
  };

  const setViewLessTagCount = (tagLength) => {
    setCount(tagLength);
    setIsViewLess(false);
  };

  const findSelectedTag = (tag) => {
    if (selectedTags?.length > 0 && tag !== "") {
      return selectedTags?.includes(tag);
    } else {
      return false;
    }
  };

  const getTagCount = () => {
    const { tags } = categoriesdata;
    if (tags.length > 0) {
      return tags.reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        if (selectedTags.includes(item)) acc += 1;
        return acc;
      }, 0);
    }
    return 0;
  };

  const tagCount = getTagCount();
  const theme = useTheme();

  return (
    <Box className={classes.menuAccordianWp}>
      {categoriesdata?.tags?.length > 0 ? (
        <Accordion
          className='accordianWp'
          sx={{
            ".Platform-x-Paper-root.Platform-x-Paper-elevation": {
              borderRadius: "0px !important",
            },
          }}>
          <AccordionSummary
            className='accordianSummaryWp'
            expandIcon={<KeyboardArrowRightIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{
              ".Platform-x-Paper-root.Platform-x-Paper-elevation": {
                borderRadius: "0px !important",
              },
              ".Platform-x-AccordionSummary-content": {
                // margin: "12px 0",
                padding: "0px 20px 0px 20px",
                // "&.Mui-expanded": {
                //   margin: "12px 0",
                // },
              },
            }}>
            {tagCount > 0 ? (
              //  <Grid>
              <Box
                sx={{
                  width: "-webkit-fill-available",
                  display: "flex",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "10px",
                  }}>
                  <img src={Tag_Icon} alt='TagIcon' />
                </Box>
                <Typography variant='h5medium'>{categoriesdata.category}</Typography>
                <Badge
                  classes={{ badge: classes.customBadge }}
                  sx={{
                    margin: "0 0 0 1rem",
                  }}
                  badgeContent={tagCount}></Badge>
              </Box>
            ) : (
              // {/* <Typography variant='h5medium'>
              //     {categoriesdata.category}
              //     <Badge
              //       classes={{ badge: classes.customBadge }}
              //       sx={{
              //         margin: "0 0 0 1rem",
              //       }}
              //       badgeContent={tagCount}
              //       //color='error'
              //     ></Badge>
              //   </Typography>
              //  </Grid>*/}
              <Box
                sx={{
                  width: "-webkit-fill-available",
                  display: "flex",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "10px",
                  }}>
                  <img src={Tag_Icon} alt='TagIcon' />
                </Box>
                <Typography variant='h5medium'>{categoriesdata.category}</Typography>
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0px 20px 0px 20px",
              ".Platform-x-Paper-root.Platform-x-Paper-elevation": {
                borderRadius: "0px !important",
              },
            }}>
            {[...(categoriesdata?.tags || [])]
              .sort()
              ?.slice(0, count)
              ?.map((tag, index1) => {
                return (
                  <ListItem
                    disabled={
                      (selectedTags.includes("Courses") && tag !== "Courses") ||
                      (selectedTags.includes("Community") && tag !== "Community") ||
                      ((single_select || multi_select) &&
                        !selectedTags?.includes(tag) &&
                        (categoriesdata.category === categoryContent ||
                          categoriesdata.category === categoryCollection))
                        ? true
                        : false
                    }
                    key={index1}
                    sx={{
                      display: "inline-flex",
                      width: "auto",
                      padding: "0px",
                      marginBottom: "12px",
                    }}>
                    <FormControlLabel
                      value={tag || ""}
                      className='vod-cat'
                      control={
                        <Checkbox
                          checked={findSelectedTag(tag)}
                          icon={<CheckCircleOutlineIcon />}
                          checkedIcon={<CheckCircleOutlineIcon />}
                          inputProps={{ id: categoriesdata.category }}
                        />
                      }
                      label={tag}
                      labelPlacement='start'
                      onChange={(e) => {
                        if (
                          (selectedTags.includes("Courses") && tag !== "Courses") ||
                          (selectedTags.includes("Community") && tag !== "Community") ||
                          ((single_select || multi_select) &&
                            (!selectedTags?.includes(tag) || selectedTags?.includes(tag)) &&
                            (categoriesdata.category === categoryContent ||
                              categoriesdata.category === categoryCollection))
                        ) {
                          return;
                        } else {
                          updateTagField(e);
                        }
                      }}
                      checked={selectedTags?.includes(tag)}
                    />
                    {count === index1 + 1 && categoriesdata.tags.length > count && (
                      <Typography
                        onClick={() => setViewMoreTagCount(categoriesdata.tags.length)}
                        variant='p3medium'
                        sx={{
                          color: theme?.palette?.customColor?.TERTIARY,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}>
                        View More
                      </Typography>
                    )}
                    {count === index1 + 1 && isViewLess && (
                      <Typography
                        onClick={() => setViewLessTagCount(10)}
                        variant='p2medium'
                        sx={{
                          color: "#374fd5",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}>
                        View Less
                      </Typography>
                    )}
                  </ListItem>
                );
              })}
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}
    </Box>
  );
};

export default GalleryLeftSideBar;
