import { Dashboard } from "@platformx/dashboard";
import React from "react";

const LazySpace = React.lazy(() =>
  import(`@platformx/community`).then((module) => ({
    default: module?.Space,
  })),
);
const LazyUserGroupListing = React.lazy(() =>
  import(`@platformx/user-groups`).then((module) => ({
    default: module?.UserGroupListing,
  })),
);
const LazyUserList = React.lazy(() =>
  import(`@platformx/user-management`).then((module) => ({
    default: module?.UserListing,
  })),
);
const LazyWorkflowManagement = React.lazy(() =>
  import(`@platformx/workflow-management`).then((module) => ({
    default: module?.WorkflowManagement,
  })),
);
const LazyNavTreeCreation = React.lazy(() =>
  import(`@platformx/nav-menu`).then((module) => ({
    default: module?.default,
  })),
);
const LazyHeaderSetting = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.HeaderSetting,
  })),
);
const LazyFooterSetting = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.FooterSetting,
  })),
);
const LazyCookieSetting = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CookieSetting,
  })),
);
const LazyMediaHandle = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.MediaHandle,
  })),
);
const LazyGlobalSetting = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.GlobalSetting,
  })),
);
const LazyTagListing = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.TagListing,
  })),
);
const LazySiteListing = React.lazy(() =>
  import(`@platformx/sites`).then((module) => ({
    default: module?.SiteListing,
  })),
);
const LazyAssetListing = React.lazy(() =>
  import(`@platformx/asset-manager`).then((module) => ({
    default: module?.AssetListing,
  })),
);
const LazyDynamicComponentContainer = React.lazy(() =>
  import(`@platformx/schema-based-content-type`).then((module) => ({
    default: module?.DynamicComponentContainer,
  })),
);
const LazyContent = React.lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.Content,
  })),
);
const LazyCollection = React.lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.Content,
  })),
);
const LazyWorkflowDetails = React.lazy(() =>
  import(`@platformx/workflow-management`).then((module) => ({
    default: module?.WorkflowDetails,
  })),
);
const LazyCreateUser = React.lazy(() =>
  import(`@platformx/user-management`).then((module) => ({
    default: module?.CreateUser,
  })),
);
const LazyCreateContent = React.lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.CreateContent,
  })),
);
const LazyCreateSpace = React.lazy(() =>
  import(`@platformx/community`).then((module) => ({
    default: module?.CreateSpace,
  })),
);
const LazyCommonContentRender = React.lazy(() =>
  import(`@platformx/content-preview`).then((module) => ({
    default: module?.CommonContentRender,
  })),
);
const LazyCreateCourse = React.lazy(() =>
  import(`@platformx/course`).then((module) => ({
    default: module?.CreateCourse,
  })),
);
const LazyCreateAsset = React.lazy(() =>
  import(`@platformx/asset-manager`).then((module) => ({
    default: module?.CreateAsset,
  })),
);
const LazyFeatureFlagSetting = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.FeatureFlagSetting,
  })),
);
const LazyCategoryDetail = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CategoryDetail,
  })),
);
const LazyCreateTags = React.lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CreateTags,
  })),
);
const LazyAddSite = React.lazy(() =>
  import(`@platformx/sites`).then((module) => ({
    default: module?.AddSite,
  })),
);
const LazyCreateArticle = React.lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.CreateArticle,
  })),
);
const LazyCreateUserGroup = React.lazy(() =>
  import(`@platformx/user-groups`).then((module) => ({
    default: module?.CreateUserGroup,
  })),
);
const LazyTimeLineBlogs = React.lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.TimeLineBlogs,
  })),
);
const LazySearchPrelem = React.lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.SearchPrelem,
  })),
);
const LazyPrelemInfo = React.lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.PrelemInfo,
  })),
);
const LazyEditPage = React.lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.EditPage,
  })),
);
const LazyAccessDenied = React.lazy(() =>
  import(`@platformx/utilities`).then((module) => ({
    default: module?.AccessDenied,
  })),
);
const LazyCharts = React.lazy(() =>
  import(`@platformx/charts`).then((module) => ({
    default: module?.Charts,
  })),
);
const LazyCommonPreview = React.lazy(() =>
  import(`@platformx/content-preview`).then((module) => ({
    default: module?.CommonPreview,
  })),
);
const LazyEcommerce = React.lazy(() =>
  import(`@platformx/report`).then((module) => ({
    default: module?.Ecommerce,
  })),
);
const LazyLoyalty = React.lazy(() =>
  import(`@platformx/report`).then((module) => ({
    default: module?.Loyalty,
  })),
);
const LazyFormBuilder = React.lazy(() =>
  import(`@platformx/form-builder`).then((module) => ({
    default: module?.FormBuilder,
  })),
);
//This component is for run prelem component on local
const LazyPrelemComponent = React.lazy(() =>
  import("../../components/PrelemLibrary/PrelemComponent").then((module) => ({
    default: module?.default,
  })),
);

const LazyChannelList = React.lazy(() =>
  import(`@platformx/channels-agora`).then((module) => ({
    default: module?.ChannelList,
  })),
);

const LazyChannelDetails = React.lazy(() =>
  import(`@platformx/channels-agora`).then((module) => ({
    default: module?.ChannelDetails,
  })),
);

const Components = {
  dashboard: Dashboard,
  space: LazySpace,
  userGroupListing: LazyUserGroupListing,
  userListing: LazyUserList,
  workflowManagement: LazyWorkflowManagement,
  navTreeCreation: LazyNavTreeCreation,
  headerSetting: LazyHeaderSetting,
  footerSetting: LazyFooterSetting,
  cookieSetting: LazyCookieSetting,
  mediaHandle: LazyMediaHandle,
  globalSetting: LazyGlobalSetting,
  tagListing: LazyTagListing,
  siteListing: LazySiteListing,
  assetListing: LazyAssetListing,
  dynamicComponentContainer: LazyDynamicComponentContainer,
  content: LazyContent,
  collection: LazyCollection,
  workflowDetails: LazyWorkflowDetails,
  createUser: LazyCreateUser,
  createContent: LazyCreateContent,
  createSpace: LazyCreateSpace,
  commonContentRender: LazyCommonContentRender,
  createCourse: LazyCreateCourse,
  createAsset: LazyCreateAsset,
  featureFlagSetting: LazyFeatureFlagSetting,
  categoryDetail: LazyCategoryDetail,
  createTags: LazyCreateTags,
  addSite: LazyAddSite,
  createArticle: LazyCreateArticle,
  createUserGroup: LazyCreateUserGroup,
  timeLineBlogs: LazyTimeLineBlogs,
  searchPrelem: LazySearchPrelem,
  prelemInfo: LazyPrelemInfo,
  editPage: LazyEditPage,
  accessDenied: LazyAccessDenied,
  userEngagement: LazyCharts,
  sitePerformance: LazyCharts,
  prelemPreview: LazyCommonPreview,
  pagePreview: LazyCommonPreview,
  contentPreview: LazyCommonPreview,
  ecommerce: LazyEcommerce,
  loyalty: LazyLoyalty,
  formBuilder: LazyFormBuilder,
  prelemComponent: LazyPrelemComponent,
  channelList: LazyChannelList,
  channelDetails: LazyChannelDetails,
};

export default Components;
